var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.total,"options":_vm.options,"fixed-header":"","height":_vm.tableHeight,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"item.claimKey",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":{ name: 'cprClaim', params: { id: item.promotion.id }, query: { claimId: item.id } },"color":"primary"}},[_vm._v(" "+_vm._s(item.claimKey)+" ")])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.createdDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}},{key:"item.calculatedTotalClaimAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.calculatedCalculatedTotalClaimAmount,2, _vm.selectedProgram))+"ClaimIncompleteTable "+_vm._s(item.calculatedCalculatedTotalClaimAmountCurrency ? item.calculatedCalculatedTotalClaimAmountCurrency : "")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.onConfirmDelete(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Claim")])])]}}])}),_c('v-dialog',{attrs:{"max-width":"650px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Delete Claim?")])]),_c('v-card-text',[_vm._v("Are you sure you want to delete Claim? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$i18n.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.onDelete()}}},[_vm._v("Agree")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }