<template>
  <v-container>
    <v-data-table
      :items="items"
      :headers="headers"
      :server-items-length="total"
      :options.sync="options"
      fixed-header
      :height="tableHeight"
      @pagination="updatePage"
      :loading="loading"
    >
      <template v-slot:item.claimKey="{ item }">
        <v-btn
          :to="{ name: 'cprClaim', params: { id: item.promotion.id }, query: { claimId: item.id } }"
          color="primary"
        >
          {{ item.claimKey }}
        </v-btn>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ item.createdDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
      </template>
      <template v-slot:item.calculatedTotalClaimAmount="{ item }">
        {{ item.calculatedCalculatedTotalClaimAmount | toNumber(2, selectedProgram) }}ClaimIncompleteTable
        {{ item.calculatedCalculatedTotalClaimAmountCurrency ? item.calculatedCalculatedTotalClaimAmountCurrency : "" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="onConfirmDelete(item)">mdi-delete</v-icon>
          </template>
          <span>Delete Claim</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline"> Delete Claim?</span>
        </v-card-title>
        <v-card-text>Are you sure you want to delete Claim? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false">{{ $i18n.translate("Cancel") }}</v-btn>
          <v-btn color="primary" @click="onDelete()" :loading="loading">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";

export default {
  name: "ClaimIncompleteTable",
  props: {
    filters: Object
  },
  data: () => ({
    deleteDialog: false,
    tableHeight: null,
    claimToDelete: {},
    items: [],
    headers: [
      {
        value: "claimKey",
        text: "Key",
        sortable: false,
        align: "center"
      },
      {
        value: "promotion.promotionKey",
        text: "Promotion Number",
        sortable: true
      },
      {
        value: "promotion.name",
        text: "Title",
        sortable: true
      },
      {
        value: "createdDate",
        text: "Created Date",
        sortable: true
      },
      {
        value: "source",
        text: "Source",
        sortable: true
      },
      {
        value: "calculatedTotalClaimAmount",
        text: "Amount",
        sortable: true,
        align: "right",
        show: true
      },
      {
        value: "errors",
        text: "Errors",
        sortable: true
      },
      {
        value: "actions",
        text: "Actions",
        align: "center",
        sortable: false
      }
    ],
    loading: false,
    total: 0,
    options: {
      sortBy: ["createdDate"],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1
    }
  }),
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    },
    filters: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  methods: {
    initialize() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = Object.assign({}, this.filters);
      filters.submitted = false;
      filters.closed = false;

      ApiService.post(
        "/api/claims/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        filters
      )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
          this.$emit("totalElements", this.total);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updatePage(pagination) {
      if (pagination.itemsPerPage == 50) {
        this.tableHeight = "30vh";
      } else {
        this.tableHeight = null;
      }
    },
    onConfirmDelete(claim) {
      this.claimToDelete = claim;
      this.deleteDialog = true;
    },
    onDelete() {
      this.loading = true;
      ApiService.post("/api/claims/" + this.claimToDelete.id + "/reject", {
        reason: "Deleted incomplete claim"
      }).then(() => {
        this.deleteDialog = false;
        this.claimToDelete = {};
        this.initialize();
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
