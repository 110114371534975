var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"height":_vm.tableHeight,"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"expanded":_vm.expanded,"fixed-header":"","single-expand":"","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"pagination":_vm.updatePage,"item-expanded":_vm.fetchClaimDetail},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ClaimDetailTable',{attrs:{"claim":item}})],1)]}},{key:"item.calculatedTotalClaimAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.calculatedCalculatedTotalClaimAmount,2, _vm.selectedProgram))+" "+_vm._s(item.calculatedCalculatedTotalClaimAmountCurrency ? item.calculatedCalculatedTotalClaimAmountCurrency : "")+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.createdDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.invoiceDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}},{key:"item.claimStage.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("formatStage")(item.claimStage,item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.claimStage.description))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }