<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{ organization ? organization.name : "" }} Claims</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Search Claims"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 110px" v-bind="attrs" v-on="on">
            <span style="width: 110px">
              <FiscalYearField
                :numberOfFutureYears="0"
                :numberOfPastYears="
                  selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1
                "
                @inputObj="
                  selectedFiscalYear = $event;
                  getData();
                "
                :client="selectedClient"
                flat
                solo-inverted
                hide-details
                class="mr-2"
                use-store
              ></FiscalYearField>
            </span>
          </div>
        </template>
        Fiscal Year
      </v-tooltip>
      <v-btn class="mr-2" @click="getData" color="primary">Search</v-btn>
      <v-btn
        v-if="
          selectedParticipant.participantType.participantTypeKey != '300' &&
            selectedParticipant.participantType.participantTypeKey != '400' &&
            $privilege.hasPrivilege('CLAIM_CREATE')
        "
        color="success"
        :to="{ name: 'cprClaims' }"
        >Start a new Claim</v-btn
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            fab
            small
            @click="$router.push({ name: 'allClaimTypesReporting' })"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
        <span>Generate Report</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text v-if="filters">
      <v-tabs v-model="tab">
        <v-tab>
          <v-badge color="primary" :content="approvedTotal" v-if="approvedTotal > 0">
            Approved
          </v-badge>
          <span v-else>Approved</span>
        </v-tab>
        <v-tab>
          <v-badge color="primary" :content="pendingTotal" v-if="pendingTotal > 0">
            Pending
          </v-badge>
          <span v-else>Pending</span>
        </v-tab>

        <v-tab>
          <v-badge color="primary" :content="incompletedTotal" v-if="incompletedTotal > 0">
            Incomplete
          </v-badge>
          <span v-else>Incomplete</span>
        </v-tab>
        <v-tab>
          <v-badge color="primary" :content="rejectedTotal" v-if="rejectedTotal > 0">
            Rejected
          </v-badge>
          <span v-else>Rejected</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item eager>
          <ClaimApprovedTable showParticipant :filters="filters" @totalElements="approvedTotal = $event" />
        </v-tab-item>
        <v-tab-item eager>
          <ClaimPendingTable showParticipant :filters="filters" @totalElements="pendingTotal = $event" />
        </v-tab-item>
        <v-tab-item eager>
          <ClaimIncompleteTable :filters="filters" @totalElements="incompletedTotal = $event" />
        </v-tab-item>
        <v-tab-item eager>
          <ClaimRejectedTable showParticipant :filters="filters" @totalElements="rejectedTotal = $event" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import ClaimRejectedTable from "../../../gapp-components/components/tables/ClaimRejectedTable.vue";
import ClaimIncompleteTable from "../../../gapp-components/components/tables/ClaimIncompleteTable.vue";
import ClaimPendingTable from "../../../gapp-components/components/tables/ClaimPendingTable.vue";
import ClaimApprovedTable from "../../../gapp-components/components/tables/ClaimApprovedTable.vue";
import FiscalYearField from "../../../gapp-components/components/fields/FiscalYearField.vue";
import { mapGetters } from "vuex";

export default {
  components: { FiscalYearField, ClaimApprovedTable, ClaimPendingTable, ClaimIncompleteTable, ClaimRejectedTable },
  name: "MyCompanyClaims",
  metaInfo: {
    title: "My Company Claims"
  },
  data() {
    return {
      organizationId: undefined,
      organization: undefined,
      tab: 0,
      search: "",
      filters: undefined,
      pendingTotal: 0,
      approvedTotal: 0,
      incompletedTotal: 0,
      rejectedTotal: 0,
      selectedFiscalYear: undefined
    };
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.getData();
    },
    getData() {
      if (!this.selectedParticipant.organization) {
        return;
      }

      let filters = {};
      if (
        (this.selectedParticipant.participantType.participantTypeKey == "300" ||
          this.selectedParticipant.participantType.participantTypeKey == "400") &&
        this.organizationId
      ) {
        filters.organization = { id: this.organizationId };
      } else {
        filters.organization = { id: this.selectedParticipant.organization.id };
      }
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.selectedFiscalYear) {
        filters.fiscalYear = this.selectedFiscalYear.fiscalYear;
      }

      this.$set(this, "filters", filters);
    },
    fetchOrganization() {
      return this.$api.get("/api/organizations/" + this.organizationId).then(({ data }) => {
        this.organization = data;
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          { text: this.organization && this.organization.name ? this.organization.name + " Claims" : "Claims" }
        ]);
      });
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    }
  },
  mounted() {
    this.organizationId = this.$route.params.id;
    if (this.organizationId) {
      this.fetchOrganization();
    }

    this.getData();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
